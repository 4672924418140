import { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../redux/authSlice";
import "./loginForm.css";
export const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch("");
  const { token } = useSelector((state) => state.auth);
  useEffect(() => {
    if (token === false) {
      navigate("/");
    } else {
      navigate("/upload");
    }
  }, [token, navigate]);
  const loginHandler = (e) => {
    e.preventDefault();
    dispatch(loginUser({ email, password }));
  };
  const forgotPasswordHandler = () => {
    navigate("/forgotpassword");
  };
  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        backgroundColor: "white",
        borderRadius: "15px",
      }}
    >
      <CssBaseline />
      <Box
        className="loginForm__div"
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "rgb(110, 185, 55)" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={loginHandler} Validate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid
              item
              xs
              sx={{ textAlign: "center", margin: "10px" }}
              onClick={forgotPasswordHandler}
            >
              <Link variant="body2">Forgot password?</Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
